import * as React from 'react';
import './Carousel.scss';
import { StaticImage } from 'gatsby-plugin-image';

const images = importAll(require.context('../../../images/customers', false, /\.(png|jpe?g|svg)$/));

function importAll(r: __WebpackModuleApi.RequireContext) {
  const images = {};
  r.keys().forEach((item: string) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

export const CustomerCarousel: React.FunctionComponent = () => (
  <section className="carouselContainer">
    <div className="scrollParent">
      <div className="scrollElement primary">
        <StaticImage src="../../../images/customers/adarga.png" alt="Adarga Logo" />
        <StaticImage src="../../../images/customers/angeleye-health-logo-white.png" alt="AngelEye Health Logo" />
        <StaticImage src="../../../images/customers/auth0-logo-white.png" alt="Auth0 Logo" />
        <StaticImage
          src="../../../images/customers/better-therapeutics-logo-white.png"
          alt="Better Therapeutics Logo"
        />
        <StaticImage src="../../../images/customers/Centrade.png" alt="Centrade Logo" />
        <StaticImage src="../../../images/customers/Changeorg-logo-white.png" alt="Change.org Logo" />
        <StaticImage src="../../../images/customers/cloudbees-logo-white.png" alt="CloudBees Logo" />
        <StaticImage src="../../../images/customers/Foundries.png" alt="Foundries Logo" />
        <StaticImage src="../../../images/customers/FutureFeed.png" alt="FutureFeed Logo" />
        <StaticImage src="../../../images/customers/INX.png" alt="INX Logo" />
        <StaticImage src="../../../images/customers/one-medical-logo-white.png" alt="OneMedical Logo" />
        <StaticImage src="../../../images/customers/setsail.png" alt="SetSail Logo" />
        <StaticImage src="../../../images/customers/spring health.png" alt="Spring Health Logo" />
        <StaticImage src="../../../images/customers/treasure-data-logo-white.png" alt="Treasure Data Logo" />
        <StaticImage src="../../../images/customers/ujet-logo-white.png" alt="UJET Logo" />
      </div>
    </div>
  </section>
);
